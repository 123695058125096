import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submitButton']

  toggle(event) {
    const isChecked = event.target.checked

    const reasonsFieldWrapper = document.getElementById('reasons-field-wrapper')

    if (isChecked) {
      reasonsFieldWrapper.classList.remove('hidden')
    } else {
      reasonsFieldWrapper.classList.add('hidden')
    }
  }

  confirmSubmit(event) {
    const confirmation = confirm('¿Está seguro de que desea re-calificar este reclamo?')

    if (!confirmation) {
      event.preventDefault()
    }
  }
}
